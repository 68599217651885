/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _replace from 'lodash/replace';

import { Layout, SEO } from '../components';

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter: FRONT_MATTER, html: HTML } = markdownRemark;
  const { title: TITLE } = FRONT_MATTER;

  /**
   * Get the length of my career.
   */
  const YEARS_WORKING = new Date().getFullYear() - 2010;
  const REPLACED_HTML = _replace(HTML, '{years_working}', YEARS_WORKING);

  return (
    <Layout>
      <SEO title={TITLE} />
      <section className="u-mt--lg">
        <div className="l-container">
          <div className="c-typography">
            <div
              className="c-typography__content"
              dangerouslySetInnerHTML={{ __html: REPLACED_HTML }}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.shape().isRequired,
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;
